<template>   
   <HeaderDash />
   <SideMenu />
   <ContentDash />
</template>

<script>

import HeaderDash from '@/components/manager/dashboard/HeaderDash.vue'
import SideMenu from '@/components/manager/dashboard/SideMenu.vue'
import ContentDash from '@/components/manager/dashboard/modules/main/ContentDash.vue'

export default 
{
  name: 'Dashboard',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentDash 
  }
}
</script>

<style>

</style>