<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-3">
    <div class="container" id="custom-cards">
      <div class="row row-cols-12 row-cols-lg-12 align-items-stretch g-4 py-5">
        <h3 class="pb-1 border-bottom text-dark-blue">Home</h3>
        <div class="container">
          <div class="row align-items-center">
            <!-- Filter project -->
            <div class="col-md-4">
              <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                <h6 class="card-subtitle mb-2 ml-1 mr-1 text-blue">Projects</h6>
              </div>
              <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                <Multiselect v-model="MultiSelectProjects.value" v-bind="MultiSelectProjects" @select="GetDataWhitProject()" ref="multiselect_projects" @clear="ClearProject" class="size-multiselect"></Multiselect>
              </div>
            </div>
            <!-- end filter-->
            <div class="col-md-4">
              <!-- filters-->
              <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2 ">
                <h6 class="card-subtitle mb-2  text-blue">Member</h6>
              </div>
              <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
               <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers"  ref="multiselect_members" @select="GetDataWhitMember()" @clear="ClearMembers" class="size-multiselect">
                  <template v-slot:tag="{ option, handleTagRemove, disabled }">
                    <div class="multiselect-tag is-user">
                      <img :src="option.icon">
                      <a :style="{'color':option.color}">{{ option.label }}</a>
                      <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                        <span class="multiselect-tag-remove-icon"></span>
                      </span>
                    </div>
                  </template>
                  <template v-slot:option="{ option }">
                    <img class="character-option-icon" :src="option.icon"> {{ option.label }}
                  </template>
                </Multiselect>
              </div>
            </div>
            <!-- Filter project -->
            <!--<div class="col-md-4">
              <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                <h6 class="card-subtitle mb-2 ml-1 mr-1 text-blue">Team</h6>
              </div>
              <div class="col-lg-12 col-xs-12 col-sm-12 text-start mt-2">
                  <Multiselect v-model="MultiSelectTeams.value" v-bind="MultiSelectTeams" @select="GetDataWhitTeam()"  ref="multiselect_teams" class="size-multiselect"></Multiselect>
              </div>
            </div>-->
            <!-- end filter-->
          </div>
        </div>
        <div class="container ">
          <div class="row ">
            <div class="col-lg-4 col-sm-6">
              <div class="col col-xs-12 col-sm-12  card card-cover  overflow-hidden  bg-white rounded-5 shadow-sm">
                 <div align="center"> 
                  <!--<donutchart />-->
                  <apexchart type="line" width="90" height="80" :options="ChartOne.chartOptions" :series="ChartOne.series"></apexchart>
                 </div>   
                <h6 class="text-blue mt-0">                  
                  <i class="fas fa-clock"></i> Time Worked
                </h6>
                <div class="row mb-2">
                  <div class="col">
                    <h5 class="card-title">{{PostData.time_today}}</h5>
                    <h6 class="card-subtitle mb-2 text-blue">Today</h6>
                  </div>
                  <div class="col">
                    <h5 class="card-title">{{PostData.time_weekly}}</h5>
                    <h6 class="card-subtitle mb-2 text-blue">Weekly</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="col col-xs-12 col-sm-12  card card-cover  overflow-hidden  bg-white rounded-5 shadow-sm">
                 <div align="center"> 
                  <!--<donutchart />-->
                  <apexchart type="line" width="90" height="80" :options="ChartOne.chartOptions" :series="ChartOne.series"></apexchart>
                 </div>   
                <h6 class="text-blue mt-2">
                  <i class="fas fa-chart-line"></i> Activity
                </h6>
                <div class="row mb-2">
                  <div class="col col-xs-12">
                    <h5 class="card-title">{{PostData.activity_today}}</h5>
                    <h6 class="card-subtitle mb-2 text-blue">Today</h6>
                  </div>
                  <div class="col">
                    <h5 class="card-title">{{PostData.activity_weekly}}</h5>
                    <h6 class="card-subtitle mb-2 text-blue">Weekly</h6>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="col-lg-3 col-sm-6">
              <div class="col col-xs-12 col-sm-12 m-3 card card-cover  overflow-hidden  bg-white rounded-5 shadow-sm">
                <h6 class="text-blue mt-2">
                  <i class="fas fa-money-bill-alt"></i> Cost
                </h6>
                <div class="row mb-2">
                  <div class="col">
                    <h4 class="card-title">{{PostData.earned_today}}</h4>
                    <h6 class="card-subtitle mb-2 text-blue">Today</h6>
                  </div>
                  <div class="col">
                    <h4 class="card-title">{{PostData.earned_weekly}}</h4>
                    <h6 class="card-subtitle mb-2 text-blue">Weekly</h6>
                  </div>
                </div>
              </div>
            </div>-->
            <div class="col-lg-4 col-sm-6">
              <div class="col col-xs-12 card card-cover  overflow-hidden  bg-white rounded-5 shadow-sm">
                <div align="center">
                  <!--<donutchart />-->
                  <apexchart type="line" width="90" height="80" :options="ChartOne.chartOptions" :series="ChartOne.series3"></apexchart>
                  </div>
                <h6 class="text-blue mt-2">
                  <i class="fas fa-project-diagram"></i> Project
                </h6>
                <div class="row mb-2">
                  <!--<div class="col-12">
                    <h5 class="card-title">{{PostData.project_worked}}</h5>
                    <h6 class="card-subtitle mb-2 text-blue">Worked </h6>
                  </div>-->
                  <div class="col">
                    <h5 class="card-title">{{PostData.project_today}}</h5>
                    <h6 class="card-subtitle mb-2 text-blue">Today</h6>
                  </div>
                  <div class="col">
                    <h5 class="card-title">{{PostData.project_worked}}</h5>
                    <h6 class="card-subtitle mb-2 text-blue">Worked</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-around">
        <div class="col-lg-4 col-sm-12">
          <div class="col-md-12 col-sm-12 col-xs-12 mt-0  mb-3 card card-cover  overflow-hidden  bg-white rounded-5 shadow-md ">
            <h5 class="text-blue m-3">Apps</h5>
            <div id="AppChart" class="chart1">
              <apexchart type="pie" width="450"  :options="AppChart.chartOptions" :series="AppChart.series"></apexchart>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="col-md-12 col-sm-12 col-xs-12 mt-0  mb-3 card card-cover  overflow-hidden  bg-white rounded-5 shadow-md ">
            <h5 class="text-blue m-3">URL</h5>
            <div id="UrlChart" class="chart1">
              <apexchart type="pie" width="450" :options="UrlChart.chartOptions" :series="UrlChart.series"></apexchart>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="col-md-12 col-sm-12 col-xs-12 mt-0  mb-3 card card-cover  overflow-hidden  bg-white rounded-5 shadow-md ">
            <div  id="WeeklyChart" class="chart1">
              <h5 class="text-blue">Weekly Activity</h5>
              <apexchart type="bar" :options="WeeklyChart.options" :series="WeeklyChart.series"></apexchart>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-0  mb-3 card card-cover  overflow-hidden  bg-white rounded-5 shadow-sm">
          <h5 class="text-blue m-3">Projects</h5>
          <div>
            <table id="project-table" class="table table-striped responsive mb-1 bg-body">
              <thead>
                <tr class="text-start">
                  <th scope="col" class="thead-bottom">
                    <span class="text-blue">Project</span>
                  </th>
                  <th scope="col" class="thead-bottom">
                    <span class="text-blue">Activity</span>
                  </th>
                </tr>
              </thead>
              <tbody class="mb-special">
                <tr class="text-start" v-for="(obj, key, index) in Projects.list" :key="index">
                  <td class="text-start"> {{obj.name}}</td>
                  <td class="text-start">
                    <div class="progress">
                      <div class="progress-bar" role="progressbar" v-bind:style="{ width: obj.activity + '%'}"   aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{obj.activity}}%</div>
                    </div>
                    <span>{{obj.time}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p-2">
              <router-link to="/dashboard/manager/project">View All</router-link>
            </div>
          </div>
        </div>
        <!-- card -->
        <div class="col-md-12  mt-0  mb-3 card card-cover  overflow-hidden  bg-white rounded-5 shadow-sm">
          <h5 class="text-blue m-3">Recent</h5>
          <div>
            <table id="screen-table" class="table table-striped responsive mb-1 bg-body">
              <thead>
                <tr class="text-start">
                  <th scope="col-2" class="thead-bottom"></th>
                  <th scope="col" class="thead-bottom">
                    <span class="text-blue">Screenshot</span>
                  </th>
                  <th scope="col" class="thead-bottom">
                    <span class="text-blue">Activity Today</span>
                  </th>
                  <th scope="col" class="thead-bottom">
                    <span class="text-blue">Activity Weekly</span>
                  </th>
                </tr>
              </thead>
              <tbody class="mb-special">
                <tr class="text-start" v-for="(obj, key, index) in Recent.list" :key="index">
                  <td class="col-2 text-start m-1">
                    <div class="row align-items-start">
                      <div class="col">
                        <span style="width: 40px; position: relative;">
                          <img :src="obj.user_picture" alt="" width="38" height="38" class="img-avatar">                        
                          <span v-bind:style="{ backgroundColor: obj.connection.color}" class="dot"></span>
                        </span>
                        <small style="font-size:12px; margin:10px">{{obj.connection.description}}</small>
                      </div>
                      <div class="mt-2">{{obj.user}}</div>
                    </div>
                    <!--<span style="width: 40px; position: relative;">
                            <img :src="obj.user_picture" alt="" width="38" height="38" class="img-avatar">                        
                             <span v-bind:style="{ backgroundColor: obj.connection.color}" class="dot"></span>
                        </span>  
                        <small style="font-size:10px; margin:10px">{{obj.connection.description}}</small>
                    <img :src="obj.screenshot" class="px-2" width="150" alt="">-->
                  </td>
                  <td class="text-start">
                    <img :src="obj.screenshot" class="px-2" width="150" alt="">
                  </td>
                  <td class="text-start">
                    <h6> {{obj.time_today}}</h6>
                  </td>
                  <td class="text-start">
                    <h6> {{obj.time_weekly}}</h6>
                  </td>
                </tr>
                
              </tbody>
            </table>
            <div class="p-2">
              <router-link to="/dashboard/manager/screenshot">View All</router-link>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </main>
  <!-- lightbox -->
</template>

<script> 
import Api from '@/services/Request'; 
import moment from 'moment-timezone'
import Multiselect from '@vueform/multiselect'
import donutchart from '../charts/donutchart'
  export default 
  {
    name: 'ContentDash',
    components: 
    {
      donutchart,
      Multiselect,
       
    },
    data: () => (
    {
      flagClear: true,
      ChartOne:
      {
        series: [
        {
          name: "Time",
          data: []
        }],
        series2: [
        {
          name: "Cost",
          data: []
        }],
        series3: [
        {
          name: "Projects",
          data: []
        }],
        chartOptions: 
        {
          chart: 
          {
            widht:'100%',
            height: 50,  
            type: 'line',
            zoom: 
            {
              enabled: false  
            },
            toolbar: 
            {
              show: false
            }
          },
          dataLabels: 
          {
            enabled: false
          },
          stroke: 
          {
            curve: 'straight',
            width: 2,
          },            
          grid:
          {
            show: false
          },
          tooltip: 
          {
            enabled: false,
          },
          yaxis:
          {
            labels:
            {
              show: false,
            },
            tooltip: 
            {
              enabled: false,
            },
          },
          xaxis: 
          {
            axisTicks: {
              show: false,
            },
            labels: 
            {
              show: false,
            },
            tooltip: 
            {
              enabled: false,
            },
            categories: [],
          }
        },
        // end 
      },
      Post:
      {
         date: new Date(),//new Date().toISOString().slice(0, 10),
         timezone: moment.tz.guess(true),
      },
      PostData:
      {
        time_today: "",
        time_weekly: "",
        activity_today: "",
        activity_weekly: "",
        earned_today: "",
        earned_weekly: "",
        project_worked: "",
        project_today: ""

      },

      MultiSelectProjects: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      MultiSelectTeams: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      MultiSelectMembers: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions: true
      },
      Projects: 
      {
        status: "false",
        list: {}
      },
      Recent: 
      {
        status: "false",
        list: {}
      },
      ListMembers: 
      {
        status: "false",
        list: {}
      },
      ListProjects: 
      {
        status: "false",
        list: {}
      },
      ListTeams: 
      {
        status: "false",
        list: {}
      },
      
      WeeklyChart: 
      {
        options: 
        {
          noData: 
          {
            text: "There's no data",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0
          },
          chart: 
          {
            id: 'WeeklyChart',            
          },
          xaxis: 
          {
            categories: []
          },
          responsive: 
          [
            {
              breakpoint: 1000,
              options: 
              {
                plotOptions: 
                {
                  bar: 
                  {
                    horizontal: true
                  }
                },
                legend: 
                {
                  position: "bottom"
                }
              }
            }
          ]     
          
        },

          series: [{
            name: 'Activity',
            data: []
          }],

      },

      AppChart: 
      {
        series: [],
          chartOptions: 
          {
            noData: 
            {
              text: "There's no data",
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0
            },
            chart: 
            {
              width: 400,
              height: 300,
              type: 'pie',
            },
            legend: {
              show: true,            
              position: 'bottom',
              horizontalAlign: 'left', 
            },
            labels: [],
            responsive: [{
              breakpoint:580,
              options: 
              {
                chart: 
                {
                  width: 300,
                },
                legend: 
                {
                  position: 'bottom'
                }
              }
            }]
          },
    
      },
      
      UrlChart: 
      {
        series: [],
          chartOptions: 
          {
            noData: 
            {
              text: "There's no data",
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: 0
            },
            chart: 
            {
              width: 400,
              height: 300,
              type: 'pie',
            },
            legend: {
              show: true,            
              position: 'bottom',
              horizontalAlign: 'left', 
            },
            labels: [],
            responsive: [{
              breakpoint: 580,
              options: 
              {
                chart: 
                {
                  width: 300
                },
                legend: 
                {
                  position: 'bottom'
                }
              }
            }]
          },
          
          
        },
    
    }),
    methods: 
    {
      ActualDate()
      {
        let date = "";
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let  month2 =  month>=10?month:'0'+ month;
        let day = new Date().getDate();
        date = year+"-"+month2+"-"+day;
        return date;
      },
      GetData() 
      {
    
        this.Post.date = this.ActualDate();
        Api.post("/dashboard/module-admin/1", this.Post).then((result) => 
        {
    
          if (result.data.status == "true") 
          {
            this.PostData.time_today = result.data.list.time_today 
            this.PostData.time_weekly = result.data.list.time_weekly
            this.PostData.activity_today = result.data.list.activity_today
            this.PostData.activity_weekly = result.data.list.activity_weekly
            this.PostData.earned_today = result.data.list.earned_today
            this.PostData.earned_weekly = result.data.list.earned_weekly 
            this.PostData.project_worked = result.data.list.project_worked 
            this.PostData.project_today = result.data.list.project_today
            //this.GetCharts(this.Post) 
          }
        })
      },
      GetDataWhitMember() 
      {
      var Post = 
        {
          date: this.ActualDate(),//new Date().toISOString().slice(0, 10),
          timezone: moment.tz.guess(true),
          id_session: this.MultiSelectMembers.value,
          id_project: this.MultiSelectProjects.value
        }

        Api.post("/dashboard/module-admin/1", Post).then((result) => 
        {
          if (result.data.status == "true") 
          {
            this.PostData.time_today = result.data.list.time_today 
            this.PostData.time_weekly = result.data.list.time_weekly
            this.PostData.activity_today = result.data.list.activity_today
            this.PostData.activity_weekly = result.data.list.activity_weekly
            this.PostData.earned_today = result.data.list.earned_today
            this.PostData.earned_weekly = result.data.list.earned_weekly 
            this.PostData.project_worked = result.data.list.project_worked 
            this.PostData.project_today = result.data.list.project_today
            this.GetCharts(Post) 
           
          }
        })
      },
      GetDataWhitTeam() 
      {
        const Post = 
        {
          date: this.ActualDate(),//new Date().toISOString().slice(0, 10),
          timezone: moment.tz.guess(true),
          id_team: this.MultiSelectTeams.value
        }

        Api.post("/dashboard/module-admin/1", Post).then((result) => 
        {
      
          if (result.data.status == "true") 
          {
            this.PostData.time_today = result.data.list.time_today 
            this.PostData.time_weekly = result.data.list.time_weekly
            this.PostData.activity_today = result.data.list.activity_today
            this.PostData.activity_weekly = result.data.list.activity_weekly
            this.PostData.earned_today = result.data.list.earned_today
            this.PostData.earned_weekly = result.data.list.earned_weekly 
            this.PostData.project_worked = result.data.list.project_worked 
            this.PostData.project_today = result.data.list.project_today
            this.GetCharts(Post) 
          }
        })
      },
      ClearMembers(option) 
      {
        if(this.MultiSelectMembers.value == null)
        {
          this.flagClear = true;
        }
        else
        {
          this.flagClear = false;
        }

        if(this.flagClear == false)
        {
          this.GetDataWhitProjectClear();
        }
      },
      ClearProject(option) 
      {
        this.MultiSelectMembers.options = [];
        this.$refs.multiselect_members.clear();

        //Clear data
        this.PostData = 
        {
          time_today: "",
          time_weekly: "",
          activity_today: "",
          activity_weekly: "",
          earned_today: "",
          earned_weekly: "",
          project_worked: "",
          project_today: ""
        };

        this.Projects = 
        {
          status: "false",
          list: {}
        };

        this.Recent = 
        {
          status: "false",
          list: {}
        };
        
        this.ListMembers = 
        {
          status: "false",
          list: {}
        };

        this.ListProjects = 
        {
          status: "false",
          list: {}
        };
      
        this.ListTeams = 
        {
          status: "false",
          list: {}
        };

        this.ChartOne.series[0].data = [];
        this.ChartOne.series2[0].data = [];
        this.ChartOne.series3[0].data = [];

        this.WeeklyChart.series = [];
        
        //Clear data charts app and url
        this.AppChart.chartOptions = {labels: []}
        this.UrlChart.chartOptions = {labels: [] } 

        for (let i = this.AppChart.series.length; i > 0; i--) 
        {
          this.AppChart.series.pop();
        }

        for (let i = this.UrlChart.series.length; i > 0; i--) 
        {
          this.UrlChart.series.pop();
        }
      },
      GetDataWhitProjectClear() 
      {
        this.flagClear = true;
        let Post = 
        {
          date: this.ActualDate(),//new Date().toISOString().slice(0, 10),
          timezone: moment.tz.guess(true),
          id_project: this.MultiSelectProjects.value 
        }

        Api.post("/dashboard/module-admin/1", Post).then((result) => 
        {
      
          if (result.data.status == "true") 
          {
            this.PostData.time_today = result.data.list.time_today 
            this.PostData.time_weekly = result.data.list.time_weekly
            this.PostData.activity_today = result.data.list.activity_today
            this.PostData.activity_weekly = result.data.list.activity_weekly
            this.PostData.earned_today = result.data.list.earned_today
            this.PostData.earned_weekly = result.data.list.earned_weekly 
            this.PostData.project_worked = result.data.list.project_worked 
            this.PostData.project_today = result.data.list.project_today
            this.GetCharts(Post) 
          }
        })
      },
      GetDataWhitProject() 
      {
        this.flagClear = true;
        this.MultiSelectMembers.options = [];
        this.$refs.multiselect_members.clear();

        let Post = 
        {
          date: this.ActualDate(),//new Date().toISOString().slice(0, 10),
          timezone: moment.tz.guess(true),
          id_project: this.MultiSelectProjects.value 
        }
        this.GetMembers();

        Api.post("/dashboard/module-admin/1", Post).then((result) => 
        {
      
          if (result.data.status == "true") 
          {
            this.PostData.time_today = result.data.list.time_today 
            this.PostData.time_weekly = result.data.list.time_weekly
            this.PostData.activity_today = result.data.list.activity_today
            this.PostData.activity_weekly = result.data.list.activity_weekly
            this.PostData.earned_today = result.data.list.earned_today
            this.PostData.earned_weekly = result.data.list.earned_weekly 
            this.PostData.project_worked = result.data.list.project_worked 
            this.PostData.project_today = result.data.list.project_today
            this.GetCharts(Post) 
          }
        })
      },
      
      GetMembers() 
      {
        Api.get("/projects-members/list-manager/"+this.MultiSelectProjects.value).then((result) => 
        {
          this.ListMembers.status = result.data.status

          if (this.ListMembers.status == "true") 
          {
            this.ListMembers.list = result.data.memberprojects

            for (var i = 0; i < this.ListMembers.list.length; i++) 
            {
              if (this.ListMembers.list[i].description == "Worker") 
              {
                var options = 
                {
                  value: this.ListMembers.list[i].id_session,
                  label: this.ListMembers.list[i].email,
                  icon: this.ListMembers.list[i].picture,
                  color: this.ListMembers.list[i].color
                }

                this.MultiSelectMembers.options.push(options)
              }
            }
          }
        })
      },
      GetProjects() 
      {
        Api.get('/projects-members/memberproject/' + null).then((result) => 
        {
          this.ListProjects.status = result.data.status

          if (this.ListProjects.status == "true") 
          {
            this.ListProjects.list = result.data.memberprojects

            for (var i = 0; i < this.ListProjects.list.length; i++) 
            {
           
                var options = 
                {
                  value: this.ListProjects.list[i].id_project,
                  label: this.ListProjects.list[i].name,

                }

                this.MultiSelectProjects.options.push(options)
              
            }
          }
        })
      },
      GetTeams() 
      {
        Api.get("/team/list/"+this.MultiSelectProjects.value).then((result) => 
        {
          this.ListTeams.status = result.data.status

          if (this.ListTeams.status == "true") 
          {
            this.ListTeams.list = result.data.team

            for (var i = 0; i < this.ListTeams.list.length; i++) 
            {
           
                var options = 
                {
                  value: this.ListTeams.list[i].id,
                  label: this.ListTeams.list[i].name,

                }

                this.MultiSelectTeams.options.push(options)
              
            }
          }
        })
      },
      GetCharts(Post) 
      {
        
        Api.post("/dashboard/module-admin/2", Post).then((result) => 
        {
        
          this.AppChart.chartOptions = {labels: []}

          this.UrlChart.chartOptions = {labels: [] } 

          for (let i = this.AppChart.series.length; i > 0; i--) 
          {
            this.AppChart.series.pop();
          }

          for (let i = this.UrlChart.series.length; i > 0; i--) 
          {
            this.UrlChart.series.pop();
          }
       

          if (result.data.status == "true") 
          {
            this.Projects.list = result.data.list.projects
            this.AppChart.chartOptions = {labels: result.data.list.apps.name } 
    
            for (var i = 0; i < result.data.list.apps.time.length; i++) 
            {
              this.AppChart.series.push(result.data.list.apps.time[i])
            }

            this.UrlChart.chartOptions = {labels: result.data.list.urls.domain } 
            
            for (var i = 0; i < result.data.list.urls.time.length; i++) 
            {
              this.UrlChart.series.push(result.data.list.urls.time[i])
            }

          }
        })

        Api.post("/dashboard/module-admin/3", Post).then((result) => 
        {

          if (result.data.status == "true") 
          {

            //
            //Changing line charts options
            //
            let options = {
              xaxis: {
                labels: {
                  show: false
                },
                categories: result.data.list.day,
              },
            };

            this.ChartOne.series[0].data = result.data.list.time;
            this.ChartOne.series2[0].data = result.data.list.cost;
            this.ChartOne.series3[0].data = result.data.list.project;
            this.ChartOne.chartOptions = options;
            //
            //Changing line charts options
            //
            
            this.WeeklyChart.options = {labels: result.data.list.day } 
            var series = []
            for (var i = 0; i < result.data.list.activity.length; i++) 
            {
              series.push(result.data.list.activity[i])
            }

            const newSeries = 
            [
                  {
                    name: "Activity",
                    data: series,
                  },
            ];

            this.WeeklyChart.series = newSeries;
          }
        })


        Api.post("/dashboard/module-admin/4", Post).then((result) => 
        {
    
          if (result.data.status == "true") 
          {
           this.Recent.list = result.data.list
          }
        })
      },
    },
    mounted() 
    {
     this.GetProjects()
    }
 }  
    
</script>

<style scooped>
.chart1 
{
  max-width: 760px;
  margin: 31px auto;
  opacity: 0.9;
}
.dot 
{
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border: 2px solid white;
  bottom: -12px;
  right: -3px;
  border-radius: 50%;
  position:absolute;

}

tr 
{
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-color: #e9e9e9;
    background-color: #FFFFFF;
}
th
{
 border-bottom-color: #ececec !important;
  background-color: #FFFFFF !important;
}
thead 
{
    border-color: inherit;
    border-style: none !important;
    border-width: 0;
    background-color: #FFFFFF !important;
}

.me-2 
{
    margin-right: 0.5rem !important;
}
.avatar-sm 
{
    width: 2.25rem;
    height: 2.25rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
}
.avatar 
{
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 600;
    height: 2.775rem;
    width: 2.775rem;
    border-radius: 0.375rem;
}
</style>
